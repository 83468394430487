import React, { ComponentProps } from "react";
import { createStyles, Box, Typography } from "@material-ui/core";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import logo from "../../assets/logo.png";
const styles = () => {
	return createStyles({
		"@global": {
			"#root": {
				padding: "1px",
			},
			body: {
				backgroundSize: "cover",
				backgroundPosition: "bottom center",
			},
			"body, html": {
				padding: 0,
				margin: 0,
				height: "100%",
				width: "100%",
			},
		},
		container: {
			width: "400px",
			margin: "100px auto 40px auto",
			textAlign: "center",
			maxWidth: "calc(100% - 40px)",
		},
		logo: {
			width: "200px",
			display: "block",
			margin: "0 auto 25px auto",
		},
	});
};

// eslint-disable-next-line @typescript-eslint/naming-convention
function SignIn(props: ComponentProps<"body"> & WithStyles): JSX.Element {
	const { classes, children } = props;
	return (
		<Box className={classes.container}>
			<img src={logo} alt="logo" className={classes.logo} />
			<Box marginBottom={4}><Typography variant="h5" color="primary">Джи Ес Евро - България ЕООД</Typography></Box>
			{children}
		</Box>
	);
}

export default withStyles(styles)(SignIn);
